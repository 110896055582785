import React from "react";
import { graphql } from "gatsby";

import Layout from "../../../shared/src/components/layout";
import Head from "../../../shared/src/components/head";

import * as styles from "./blog-post.module.css";

const BlogPost = ({ data, location }) => {
  const post = data.markdownRemark;
  const { date, title } = post.frontmatter;
  const { siteTitle, menu } = data.site.siteMetadata;

  return (
    <Layout location={location} title={siteTitle} menu={menu}>
      <Head title={title} description={post.excerpt} />
      <h1>
        {" "}
        <span> {title} </span>{" "}
      </h1>
      <h4 className={styles.date}>
        <span> {date} </span>
      </h4>
      <div dangerouslySetInnerHTML={{ __html: post.html }} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        menu
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`;

export default BlogPost;
